var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" tw-w-full"},[(_vm.loading)?_c('loader',{attrs:{"color":_vm.getColor,"loading":_vm.loading}}):_vm._e(),_c('div',{staticClass:"main-sculptor"},[_c('div',{staticClass:"tw-flex  tw-w-full"},[_c('div',{staticClass:"desc-boxes tw-flex",attrs:{"id":"first-desc-box"},on:{"click":_vm.routeToCreateHiring}},[_c('div',{staticClass:"eclipse-container"},[_c('div',{staticClass:"eclipse"},[_c('Icons',{attrs:{"name":"create-hiring"}})],1)]),_vm._m(0)]),_c('div',{staticClass:"desc-boxes tw-flex tw-ml-5",on:{"click":function($event){return _vm.$router.push({name: 'AllHiringCriteria'})}}},[_c('div',{staticClass:"eclipse-container"},[_c('div',{staticStyle:{"background":"rgba(118, 179, 199, 0.12)","border-radius":"50%","margin-right":"10px","padding":"10px","cursor":"pointer"}},[_c('Icons',{attrs:{"name":"driving-icon"}})],1)]),_vm._m(1)]),_vm._m(2)]),_c('div',{staticClass:"table"},[_c('p',{staticClass:"header-txt",style:({color: _vm.companyData.companyColor})},[_vm._v("All Request")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"theme":"none","mobile-breakpoint":0,"item-key":"id","single-select":false,"height":"available","items":_vm.allHires},scopedSlots:_vm._u([{key:"header.fleet",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Fleet")])]},proxy:true},{key:"item.fleet",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"circle"},[_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.vehicleBrand))])])]}},{key:"header.date",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Date")])]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.date))])]}},{key:"header.time",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Time Period")])]},proxy:true},{key:"item.timePeriod",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.fleet.createdAt))])]}},{key:"header.pickUp",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Pick-Up")])]},proxy:true},{key:"item.pickUpLocation",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.departureCity + " " + item.departureState))])]}},{key:"header.dropOff",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Drop-Off")])]},proxy:true},{key:"item.dropOffLocation",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.destinationCity + " "+ item.destinationState))])]}},{key:"header.status",fn:function(){return [_c('span',{staticClass:"table-header"},[_vm._v("Status")])]},proxy:true},{key:"item.hireStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status"},[_c('span',{staticClass:"mt-1 mr-2"},[_c('StatusInfo',{attrs:{"statusText":item.fleet.fleetServiceStatus}})],1),_c('span',{staticClass:"table-header-values"},[_vm._v(_vm._s(item.fleet.fleetServiceStatus))])])]}}],null,true)})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desc-text-container "},[_c('p',{staticClass:"desc-txt"},[_vm._v("New Hiring Criteria")]),_c('p',{staticClass:"desc-txt-small"},[_vm._v("Create a Hiring Criteria")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desc-text-container "},[_c('p',{staticClass:"desc-txt"},[_vm._v("View Hiring Criteria")]),_c('p',{staticClass:"desc-txt-small"},[_vm._v("All Hiring Criteria")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desc-boxes tw-flex tw-ml-5",attrs:{"id":"desc-box"}},[_c('div',{staticStyle:{"height":"100%","width":"90px"}},[_c('img',{staticStyle:{"height":"100% !important"},attrs:{"src":require("@/assets/bus-image.png"),"width":"100%","alt":"a bus"}})]),_c('div',{staticClass:"desc-text-container ml-3"},[_c('p',{staticClass:"desc-txt"},[_vm._v("23")]),_c('p',{staticClass:"desc-txt-small"},[_vm._v("Hiring Criteria")])])])}]

export { render, staticRenderFns }
<template>
  <div class=" tw-w-full">
    <loader :color="getColor" v-if="loading" :loading="loading"/>
    <div class="main-sculptor">
    <div class="tw-flex  tw-w-full">

      <div class="desc-boxes tw-flex" id="first-desc-box" @click="routeToCreateHiring">
        <div class="eclipse-container">
          <div class="eclipse"><Icons name="create-hiring"/></div>
        </div>
        <div class="desc-text-container ">
          <p class="desc-txt">New Hiring Criteria</p>
          <p class="desc-txt-small">Create a Hiring Criteria</p>
        </div>
      </div>

      <div class="desc-boxes tw-flex tw-ml-5" @click="$router.push({name: 'AllHiringCriteria'})">
        <div class="eclipse-container">
          <div style="background: rgba(118, 179, 199, 0.12); border-radius: 50%; margin-right: 10px; padding: 10px; cursor: pointer;">
            <Icons name="driving-icon"/></div>
        </div>
        <div class="desc-text-container ">
          <p class="desc-txt">View Hiring Criteria</p>
          <p class="desc-txt-small">All Hiring Criteria</p>
        </div>
      </div>

      <div class="desc-boxes tw-flex tw-ml-5" id="desc-box">
          <div  style="height: 100%; width:90px"><img src="@/assets/bus-image.png" width="100%" style="height:100% !important" alt="a bus"></div>
        <div class="desc-text-container ml-3">
          <p class="desc-txt">23</p>
          <p class="desc-txt-small">Hiring Criteria</p>
        </div>
      </div>
    </div>

    <div class="table">
      <p class="header-txt" :style="{color: companyData.companyColor}">All Request</p>
      <v-data-table :headers="headers" theme="none" :mobile-breakpoint="0" item-key="id" :single-select="false"
                    height="available"
                    :items="allHires"
                    >

        <template #header.fleet>
          <span class="table-header">Fleet</span>
        </template>

        <template v-slot:[`item.fleet`]="{ item }">
          <div class="circle">
            <span class="table-header-values">{{ item.vehicleBrand}}</span>
          </div>
        </template>

        <template #header.date>
          <span class="table-header">Date</span>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <span class="table-header-values">{{ item.date}}</span>
        </template>

        <template #header.time>
          <span class="table-header">Time Period</span>
        </template>

        <template v-slot:[`item.timePeriod`]="{ item }">
          <span class="table-header-values">{{ item.fleet.createdAt}}</span>
        </template>

        <template #header.pickUp>
          <span class="table-header">Pick-Up</span>
        </template>

        <template v-slot:[`item.pickUpLocation`]="{ item }">
          <span class="table-header-values">{{ item.departureCity + " " + item.departureState }}</span>
        </template>

        <template #header.dropOff>
          <span class="table-header">Drop-Off</span>
        </template>

        <template v-slot:[`item.dropOffLocation`]="{ item }">
          <span class="table-header-values">{{ item.destinationCity + " "+ item.destinationState }}</span>
        </template>

        <template #header.status>
          <span class="table-header">Status</span>
        </template>

        <template v-slot:[`item.hireStatus`]="{ item }">
          <div class="status">
            <span class="mt-1 mr-2"><StatusInfo :statusText="item.fleet.fleetServiceStatus"></StatusInfo></span>
            <span class="table-header-values">{{item.fleet.fleetServiceStatus}}</span>
          </div>
        </template>


      </v-data-table>

    </div>

  </div>
  </div>

</template>

<script>
import {getAllHireOfTsp} from "@/services/api/APIService";
import Icons from "@/components/reuseables/Icons.vue";
import StatusInfo from "@/components/reuseables/StatusInfo.vue";
import Loader from "@/components/reuseables/Loader.vue";
import {mapGetters} from "vuex";

export default {
  name: "LeasingDashboard",
  components: {Loader, StatusInfo, Icons },
  data(){
    return{
    allHires: [{
      companyData:{}
    }],

    headers:[
      {
        text: "Fleet",
        value: "fleet"
      },
      {
        text: 'Date',
        value: 'createdAt'
      },
      {
        text: 'Time Period',
        value: 'duration'
      },
      {
        text: 'Pick-Up',
        value: 'pickUpLocation'
      },
      {
        text: 'Drop-Off',
        value: 'dropOffLocation'
      },
      {
        text: 'Status',
        value: 'hireStatus'
      },
    ],
      loading: false,
    }
  },


  computed:{
    ...mapGetters("companyProfile",["companyData"]),

    getColor() {
      let data = JSON.parse(sessionStorage.getItem("companyData"));
      let color = "#0044AD";
      if (data) {
        color = data.companyColor;
      }
      return color;
    },
  },

  methods:{
    routeToCreateHiring(){
      this.$router.push({name: 'CreateHiringCriteria'})
    },

    async getAllHireRequest(){
      // this.loading = true
      let data = {}
      data.transportCompanyId = this.companyData.id
      await getAllHireOfTsp(data).then(res => {
        console.log(this.companyData.id)
          this.allHires = res.data
      }).catch(err => {
        // this.loading = false;
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        console.log(err)
      })
    },
  },
  async created(){
    // this.loading = false
    await this.getAllHireRequest()
  }

}
</script>

<style scoped lang="scss">
.main-sculptor{
  width: 100%;
  min-height: 70vh;
  @media (max-width:768px) {
    width: 100%;
    margin-left: 120px;
  }
  @media screen and (max-width: 375px){
    padding: 50px;
    margin-left: 50px;
  }
}
.desc-boxes {
  background: #FDFFFC;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  padding:0 30px;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 375px){
    margin-bottom: 30px;
    width: 80%;
    height: 130px;
  }
}
.info-box{
  @media screen and (max-width: 375px){
    display: flex;
    flex-wrap: wrap;
    //margin-right: 130px;
    margin-left: -90px;
    margin-top: 20px;
  }
}
//.desc-text-container{
//  flex-direction: column;
//  @media screen and (max-width: 375px){
//    flex-direction: inherit;
//  }
//}
#desc-box{
  padding: 0px;
  height:100px;
  cursor: default;
}

.desc-txt {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  color: #4F4F4F;
  margin-bottom: 5px !important;
}

.desc-txt-small {
  font-family: Inter;
  font-size: 8px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 0px !important;
}


.eclipse {
  background: #FAAFF2;
  border-radius: 50%;
  margin-right: 10px;
  padding: 10px;
}
.header-container{
  height: fit-content;
  width :187px;
  padding: 13px;
  border-radius: 10px;
  background: rgba(253, 255, 252, 0.4);

  @media screen and (max-width: 375px){
    margin-left: -40px;
  }
}
.all-hire-txt{
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #004AAD;
}
.table{
  width: 100%;
  border-radius: 10px;
  margin-top: 50px;
  background: #FFFFFF;
  padding: 40px;
  @media screen and (max-width: 375px){
    margin-left: -50px;
    width: 110%;
  }
}
.header-txt{
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #004AAD;
}
.table-header{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
}
.table-header-values{
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
}
.status{
  display: flex;
}
</style>

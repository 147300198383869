<script>

export default {
  name: 'StatusInfo',
  props: {
    statusText: {
      type: String,
      required: true
    }
  },

  methods:{
    getStatusColour(text){
      if(text === "ACTIVE"){
        return '#00C48C'
      }else if (text === "CANCELLED"){
        return '#C84867'
      }else return "#FFE031"
    }
  }
}
</script>

<template>
<div>

  <div  :style="{ width: '9px', height: '9px', borderRadius: '50%', backgroundColor: getStatusColour(statusText) }"></div>

</div>
</template>

<style scoped lang="scss">

</style>